h1 {
	font-size: 2.4em;
	display: flex;
	justify-content: center;
}

nav {
  	margin-top: 1.5em;
  	display: flex;
	align-items: center;
	flex-direction: column;

	@include medium-screen {
		flex-direction: row;
		justify-content: center;
    }
}

.navLink {
    width: auto;
    height: 100%;
    padding: .3em 1em;
    transition: all .15s linear;
    border-bottom: none;
    color: $light-gray;
    border-radius: $base-border-radius;
    font-size: .8em;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	cursor: pointer;

    &:hover {
    	background: $base-accent-color;
    	color: $white;
    }
}
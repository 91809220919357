.graySection {
	background: $gray-lightest;

	&:after {
			@include triangle($gray-lightest);

	}
}

.sectionHeader {
	margin: 0.75em 0 0 0;
}

.extracurricularSection {
	display: flex;
	flex-wrap: wrap;
	max-width: $max-width;
	margin: 0 auto;

	.extracurricular {
		@include cardStyles;
		padding-top: 0.5em;
		
		p {
			margin-bottom: 0.25em;

			&.name {
				font-size: 1.1em;
			}

			&.title,
			&.date,
			&.place {
				font-weight: 100;
				font-size: 0.9em;
				color: $gray;
			}
		}

		span, a {
			text-transform: none;
			font-size: 0.9em;
		}

		a {
			padding-left: 0.2em;
			white-space: nowrap;

			span {
				font-size: 1.1em;
				padding-left: 0.2em;
			}
		}
	}
} 
@font-face {
    font-family: 'Nexa Bold';
    src: url('/public/fonts/Nexa_Free_Bold-webfont.eot');
    src: url('/public/fonts/Nexa_Free_Bold-webfont.eot?#iefix') format('embedded-opentype'),
         // url('/public/fonts/Nexa_Free_Bold-webfont.woff') format('woff'),
         // url('/public/fonts/Nexa_Free_Bold-webfont.ttf') format('truetype'),
         url('/public/fonts/Nexa_Free_Bold-webfont.eot.svg#nexabold') format('svg');
}

@font-face {
    font-family: 'Nexa Light';
    src: url('/public/fonts/Nexa_Free_Light-webfont.eot');
    src: url('/public/fonts/Nexa_Free_Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/Nexa_Free_Light-webfont.woff') format('woff'),
         url('/public/fonts/Nexa_Free_Light-webfont.ttf') format('truetype'),
         url('/public/fonts/Nexa_Free_Light-webfont.eot.svg#nexalight') format('svg');
}

$base-font-family: 'Nunito', sans-serif;
$header-font-family-bold: 'Nexa Bold', sans-serif;

body {
	font-family: $base-font-family;
	color: $base-font-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family-bold;
  line-height: $header-line-height;
  margin: 0;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 ($base-spacing / 2);
}

a {
  transition: color 0.1s linear;
  color: $base-link-color;
  text-decoration: none;

  &:hover {
    color: $hover-link-color;
  }

  &:active, &:focus {
    color: $hover-link-color;
    outline: none;
  }
}

a.light:hover {
	color: $hover-link-color-darker;
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 99%;
}

blockquote {
  border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-spacing 0;
  padding-left: $base-spacing / 2;
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: "\2014 \00A0";
  }
}

a { 
	cursor: pointer; 
}

.aboutHeader {
	margin: 0 0 0.5em;
}

p {
	margin-bottom: 3em;
}

.socialButtons {
	display: flex;
	margin-top: 0.5em;

	a {
		flex-basis: 2em;
		border-radius: $base-border-radius;
		padding-right: 1.5em;

		svg {
			height: 2em;
			width: 2em;
			fill: $base-font-color;
					transition: all .15s linear;

		}

		&:hover {
			svg {
				fill: $base-accent-color;
			}
		}
	}
}
@import "./reset.css";
@import "./variables.scss";
@import "./typography.scss";
@import "./components/header/Header.scss";
@import "./components/card/Card.scss";
@import "./components/cardGrid/CardGrid.scss";
@import "./components/seeMoreModal/SeeMoreModal.scss";
@import "./components/extracurriculars/Extracurriculars.scss";
@import "./components/about/About.scss";

body {
	margin: 0;
}

.App {
    width: 100%;
    position: relative;
    padding-top: 2em;
}

.contentSection {
	max-width: $max-width;
	margin: 0 auto;
	padding: 3em 2em;
	position: relative;

	@include max-width {
		padding: 5em 0;
	}

	&.withWhiteArrow:after {
		@include triangle($white);
	}
}

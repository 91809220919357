
// Font Sizes
$base-font-size: 1em;
$h1-font-size: $base-font-size * 2.25;
$h2-font-size: $base-font-size * 2;
$h3-font-size: $base-font-size * 1.75;
$h4-font-size: $base-font-size * 1.5;
$h5-font-size: $base-font-size * 1.25;
$h6-font-size: $base-font-size;

// Line height
$base-line-height: 1.5;
$header-line-height: 1.25;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$base-z-index: 0;

// Colors
$blue: 							#84CAE7;
$blue-dark:						#477998;
$green:                 		#12cc9c;
$red:                   		#e4342b;
$yellow:                		#FCFC62;
$orange:                		#ff8a00;
$pink:                 	 		#e1c4c5;
$purple:                		#33385d;
$navy:                  		#35414d;
$teal:							#1E575F;
$light-red: 					#FBE3E4;
$light-yellow: 					#FFF6BF;
$light-green: 					#E6EFC2;

// Grays
$gray:                 			#666;
$dark-gray: 					#444;
$medium-gray: 					#ccc;
$light-gray: 					#7b7570;
$gray-lighter:          		#eee;
$gray-lightest:         		#f2f2f2;
$black:                	 		#000;
$white:                 		#fff;
$off-white:						#fafafa;

$base-font-color: $dark-gray;
$primary-color: $green;
$accent-color: $blue;


// Background Color
$base-background-color: $white;
$base-wrapper-color: $off-white;
$footer-bg-color: $dark-gray;

// Font Colors
$base-font-color: $dark-gray;
$base-accent-color: darken($accent-color, 15);
$footer-font-color: $off-white;

// Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 10);
$hover-link-color-darker: darken($base-accent-color, 15);
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Flash Colors
$alert-color: $light-yellow;
$error-color: $light-red;
$notice-color: lighten($base-accent-color, 40);
$success-color: $light-green;

// Border color
$base-border-color: $gray-lighter;
$base-border: 1px solid $base-border-color;

// Forms
$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px rgba(black,0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $base-font-size;
$form-font-family: 'Open Sans', sans-serif;

// Media queries
$small-screen: 480px;
$medium-screen: 768px;
$max-width: 1200px;

@mixin small-screen {
	@media (min-width: $small-screen) { @content; }
} 

@mixin medium-screen {
	@media (min-width: $medium-screen) { @content; }
} 

@mixin max-width {
	@media (min-width: $max-width) { @content; }
} 

// Mixins
@mixin cardStyles {
	@include medium-screen {
		flex-basis: 20em;
		flex-grow: 1;
		margin-right: 2em;
	}
}

@mixin triangle($color) {
	content: '';
	width: 0; 
	height: 0; 
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid $color;
	position: absolute;
    left: 50%;
    margin-left: -26px;
}


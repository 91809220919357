.card {
	@include cardStyles;
	padding-top: 1em;

	h5 {
		margin-bottom: 0.25em;
	}
}

.card-image {
	height: auto;
	width: 100%;
	overflow: hidden;
	margin-bottom: 0.8em;
	img {
		width: 100%;
		border: 1px solid $gray-lighter;
		border-radius: $base-border-radius;
		object-position: bottom;
	    object-fit: cover;
	    // max-width: 266px;
	    // max-height: 166px;
	}
}

.card-copy p {
	font-size: $base-font-size;
	font-weight: 100;
	margin-bottom: 1em;

	a {
		color: $base-font-color;

		&:hover {
			color: $base-font-color;
			text-decoration: underline;
		}
	}
}

ul.techs {
	display: block;
	padding: 0 0 0.5em 0;
	list-style: none;
	margin: 0;

	li {
		display: inline-block;
		color: $primary-color;
		border: 1px solid $primary-color;
		border-radius: 3px;
		padding: .2em .3em;
		margin: 0 .2em .4em 0;
		font-size: 0.9em;
	}
}

span, .cardLink {
	font-size: .8rem;
	text-transform: uppercase;
}

.disclaimer {
	font-size: 0.8em;
	text-style: italic;
	color: $light-gray;
	margin: 1em 0 0;
	font-weight: 100;
}